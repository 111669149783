import React from "react";

import Seo from "../components/Seo";
import { Heading1, Intro } from "../components/Atoms/Text";

const NotFoundPage = () => (
  <>
    <Seo title="404: Sidan kunde inte hittas" meta={[{name: "robots", content: "noindex"}]}/>
    <Heading1>Sidan kunde inte hittas</Heading1>
    <Intro></Intro>
  </>
);

export default NotFoundPage;
